import {defineStore} from 'pinia'

export const useSidebarStore = defineStore('sidebar', () => {
    const isSidebarOpen = ref(false)
    const component = ref('')
    const activeTab = ref('')
    const isOverlay = ref(false)
    const viewMode = ref(false)

    function resetSidebar() {
        isSidebarOpen.value = false
        component.value = ''
        activeTab.value = ''
        isOverlay.value = false
        viewMode.value = false
    }

    return {
        isSidebarOpen,
        component,
        activeTab,
        isOverlay,
        viewMode,
        resetSidebar,
    }
})
